import http from "../http";
import Service from "../../Service";

/**
 * @type SellerService
 */
export default class SellerService extends Service {

    load(params) {
        return http.get(`admin/sellers`, {
			headers: this.getHeaders(),
            params
		});
    }

    show(id) {
        return http.get(`admin/sellers/${id}`, {
			headers: this.getHeaders()
		});
    }

	update_status(id, data) {
		return http.patch(`admin/sellers/${id}/status`, data, {
			headers: this.getHeaders()
		});
	}

    deactivate_client(id, client_id) {
		return http.patch(`admin/sellers/${id}/clients/deactivate`, { client_id }, {
			headers: this.getHeaders()
		});
	}

    activate_client(id, client_id) {
		return http.patch(`admin/sellers/${id}/clients/activate`, { client_id }, {
			headers: this.getHeaders()
		});
	}

    toggle_client(client, id) {
        return client.is_attending ? this.deactivate_client(id, client.id) : this.activate_client(id, client.id)
    }

    update_status(id, data) {
		return http.patch(`admin/sellers/${id}/status`, data, {
			headers: this.getHeaders()
		});
	}

    create(seller) {
        return http.post(`admin/sellers`, seller, {
			headers: this.getHeaders()
		});
    }

    update(seller) {
        return http.put(`admin/sellers/${seller.id}`, seller, {
			headers: this.getHeaders()
		});
    }

    save(seller) {
        return seller.id ? this.update(seller) : this.create(seller)
    }

    clients(id) {
        return http.get(`admin/sellers/${id}/clients`, {
			headers: this.getHeaders()
		});
    }

    available_clients(id, params) {
        return http.get(`admin/sellers/${id}/clients/available`, {
			headers: this.getHeaders(),
            params
		});
    }

    remove(id) {
		return http.delete(`admin/sellers/${id}`, {
			headers: this.getHeaders()
		});
	}

	allSupliers(){
		return http.get(`admin/providers/all`, {
			headers: this.getHeaders()
		});
	}

}
